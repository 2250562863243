<template>
  <v-container id="clientes-ubicacion" tag="section" class="pa-0" style="max-width: 100%;">
    <!--  SECTION MAP -->
    <v-row no-gutters>
      <v-col cols="12" sm="12" :style="height_mapa">
        <view-maps :points="markers" :action="action" :loadData="loading" info :centerable="center_cli" :animation="center_cli"
          :area="showCustomVisit" @exportar="exportarClientes" @details="detailsClientes" :iconSize="sizeIcon"
          :zoom="zoom_map" />
        <v-overlay v-if="loading" :value="true" color="white" absolute opacity="1" key="mapaClientesOverlay"
          style="z-index: 1002 !important">
          <div class="d-flex flex-column justify-center">
            <v-progress-circular indeterminate size="64" color="primary" class="mx-6"></v-progress-circular> <br>
            <span class="primary--text py-5">Obteniendo Datos</span>
          </div>
        </v-overlay>
      </v-col>
    </v-row>

    <!-- ######LISTA GENARAL DE VENDEDORES #####-->
     <!--
    <v-navigation-drawer v-show="showInfoData" v-model="showListVend" absolute color="grey lighten-5" width="30vw"
      class="elevation-1">
      <v-app-bar dark color="blue-grey">
        <v-toolbar-title>Vendedores</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showInfoData = false">
          <v-icon>mdi-chevron-left-circle</v-icon>
        </v-btn>
      </v-app-bar>
      <v-row class="px-4 pt-5">
        <v-col cols="12">
          <search-expand v-model="search_vend" :load="loading" placeholder="Buscar Vendedor" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0">
          <v-data-iterator :items="itemsVend" :loading="loading" :search="search_vend" hide-default-footer
            class="mb-0 pb-0">
            <template v-slot:default="props">
              <v-list two-line class="pt-0">
                <template v-for="(vendedor, i) in props.items">
                  <v-list-item>
                    <v-list-item-avatar color="white">
                      <v-icon :color="vendedor.color">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-bold">{{ vendedor.ven_des }}
                          ({{ vendedor.co_ven }})
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="font-weight-regular">{{ vendedor.cli_des || vendedor.nombre_comercial }} <template
                            v-if="vendedor.co_cli !== ''"> ({{ vendedor.co_cli }}) </template></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu bottom min-width="200" offset-y origin="top right" transition="scale-transition">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn v-bind="attrs" v-on="on" icon :loading="vendedor.loading" :disabled="vendedor.loading">
                            <v-icon color="grey lighten-1">mdi-dots-vertical-circle</v-icon>
                          </v-btn>
                        </template> -->
                        <!--------------- OPCIONES DE REUTA DEL DIA Y PERSONALIZACION ------------->
                        <!--
                        <v-list class="py-0" flat>
                          <v-list-item-group color="primary">
                            <template>
                              <v-list-item class="py-2" @click="getVendMap(vendedor)">
                                <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                                  <v-icon color="blue-grey">
                                    mdi-information
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                  <span>Ruta del Día</span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider />
                            </template>
                            <v-list-item class="py-2" @click="getCustomVisits(vendedor)">
                              <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                                <v-icon color="blue-grey">mdi-tune</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Personalizado</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="i < props.items.length - 1" />
                </template>
              </v-list>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  -->
    <div style="position: absolute; z-index: 20; top: 0px; right: 120px; background-color: white; border-radius: 4px; margin-top: 10px;">
      <v-list-item style="min-height: 40px !important;" class="align-self-center" @click="getCustomVisits(vendedor)">
        <v-list-item-icon class="my-0 ml-1 mr-3 align-self-center">
          <v-icon color="blue-grey">mdi-tune</v-icon>
        </v-list-item-icon>
        <span>Seleccionar fecha</span>
      </v-list-item>
    </div>
    <!--- ------------------------------------------------------------------------------------------>
    <!--- ------------------------------------------------------------------------------------------>
    <!-- ######LISTA DE VISITAS DE UN VENDEDOR #####-->
    <v-navigation-drawer v-model="showDetailsVend" v-show="showInfoData" absolute color="grey lighten-5" width="30vw"
      class="elevation-1">
      <v-app-bar dark color="blue-grey">
        <!-- <v-btn icon @click="backList">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn> -->
        <span>Vendedor:</span>
        <v-toolbar-title class="ml-4">{{ venDetails.ven_des }} ({{ venDetails.co_ven }})</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showInfoData = false">
          <v-icon>mdi-chevron-left-circle</v-icon>
        </v-btn>
      </v-app-bar>
      <v-divider class="mb-4"></v-divider>
      <v-row>
        <v-col cols="12" class="pl-0">
          <v-timeline align-top dense class="pt-0 mt-4">
            <template v-if="venDetails.visitas && venDetails.visitas.length > 0">
              <v-timeline-item v-for="(visita, i) in venDetails.visitas" :color="visita.color" fill-dot
                icon="mdi-map-marker-radius" small class="mb-4">
                <v-row class="pt-3 pointer-cursor">
                  <v-col cols="12" sm="12" class="pa-0">
                    <v-card flat tile class="fill-height">
                      <v-card-text class="grey lighten-5 blue-grey--text pa-0">
                        <v-row class="py-0" @click="centerCliente(visita)">
                          <template v-if="visita.cli_des">
                            <v-col cols="12" class="pb-1">
                              <span class="font-weight-bold primary--text">
                                {{ i + 1 }} - {{ visita.cli_des || visita.nombre_comercial }}
                                <template v-if="visita.co_cli">({{ visita.co_cli }})</template>
                              </span>
                            </v-col>
                          </template>
                        </v-row>
                        <v-row class="py-0"  @click="centerCliente(visita)">
                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-clock-outline</v-icon>
                              Inicio:
                            </span>
                            <span class="">{{ visita.hora_inicio }}</span>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-clock-outline</v-icon>
                              Final:
                            </span>
                            <span class="">{{ visita.hora_fin }}{{ visita.cod_pedido }}</span>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-alarm-check</v-icon>
                              Duración:
                            </span>
                            <span class="">{{ visita_duracion(visita.hora_inicio, visita.hora_fin) }}</span>
                          </v-col>

                          <v-col cols="12" class="py-0" v-if="visita.cod_pedido">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-map-marker-radius-outline</v-icon>
                              Punto de visita:
                            </span>
                            <span class="">{{ visita.cod_pedido }}</span>
                          </v-col>

                          <v-col cols="12" class="py-0">
                            <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-cart-check</v-icon>
                              Tipo Visita:
                            </span>
                            <span class="">{{ visita.tipo_visita ? visita.tipo_visita: 'N/A' }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="py-0">
                          <v-col cols="12" class="py-0">
                            <!-- <span class="caption font-weight-medium">
                              <v-icon size="16" color="blue-grey" class="mt-n1">mdi-clock-outline</v-icon>
                              Ver Detalles:
                            </span>
                            <span class="">{{ visita.hora_inicio }}</span> -->
                            <div class="btn-detail text-subtitle-1 rounded-lg my-2" @click="viewDetails(visita)">
                              <v-icon class="pl-2" center color="white">mdi-file-check-outline</v-icon>
                              Ver detalle
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </template>
            <template v-else>
              <v-timeline-item :color="visita.color" fill-dot
                icon="mdi-map-marker-radius" color="red" small class="mb-4">
                <v-row class="pt-3">
                  <v-col cols="12" sm="12" class="pa-0">
                    <v-card flat tile class="fill-height">
                      <v-card-text class="grey lighten-5 blue-grey--text pa-0">
                        <v-row class="py-0">
                          <template>
                            <v-col cols="12" class="pb-1">
                              <span class="font-weight-bold red--text">
                                {{ 1 }} - Sin Visitas
                              </span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </template>
          </v-timeline>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- ###### LISTA DE VISITAS PLANIFICADAS #####-->
    <v-navigation-drawer v-model="showCustomVisit" v-show="showInfoData" absolute color="grey lighten-5" width="30vw"
      class="elevation-1">
      <v-app-bar dark color="blue-grey">
        <v-btn icon @click="backList">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ venDetails.ven_des }} ({{ venDetails.co_ven }})</v-toolbar-title>
        <v-spacer></v-spacer>
        <consultar-rango-fecha-mapa @consultar="getData" />
        <v-btn icon @click="showInfoData = false">
          <v-icon>mdi-chevron-left-circle</v-icon>
        </v-btn>
      </v-app-bar>

      <v-row class="pa-4">
        <v-col cols="12" class="d-flex flex-column pb-0">
          <div class="d-flex flex-column">
            <span class="caption blue-grey--text pb-1">
              <v-icon size="16" class="mt-n1">mdi-calendar</v-icon>
              Fechas Selecciona:
            </span>
            <span class="font-weight-bold text-h6">
              <template>
                <span class="hidden-xs-only">{{ fechas.selected }}:</span>
              </template>
              {{ fechas.ini | smartDate }} - {{ fechas.fin | smartDate }}</span>
          </div>
          <v-checkbox v-model="toggle_all" hide-details color="info" class="ml-1">
            <template #label>
              <span class="ml-4 font-weight-medium">Todos</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0">

          <v-expansion-panels focusable hover flat accordion>
            <template v-for="(visita, i) in customVisitas">
              <v-expansion-panel active-class="blue-grey lighten-5">
                <v-expansion-panel-header class="px-2 py-0">
                  <v-list class="py-0 transparent" dense>
                    <v-list-item class="py-2">
                      <v-list-item-action>
                        <v-checkbox v-model="visita.active" color="info" @click.native.stop="stop"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content class="d-flex align-center">
                        <v-list-item-title style="white-space: normal !important"
                          class="text-uppercase font-weight-bold">
                          <v-icon :color="visita.color" left v-text="'mdi-map-marker'"></v-icon>
                          {{ visita.fecha | diaSemana }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="white" class="cxc-content">
                  <v-row no-gutters>
                    <v-col cols="12" class="px-0 mx-0">
                      <v-list two-line class="pt-0">
                        <template v-for="(cli, i) in visita.clientes">
                          <v-list-item>
                            <v-list-item-avatar color="white">
                              <v-icon :color="visita.color">mdi-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="font-weight-regular">{{ cli.cli_des }} ({{ cli.co_cli }})</span>
                              </v-list-item-title>
                              <v-list-item-title>
                                <div class="pt-3 d-flex justify-space-between fill-width">
                                  <div class="d-flex">
                                    <span v-if="cli.frecuencia && cli.frecuencia !== null" class="blue-grey--text mr-2">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon color="blue-grey lighten-1 " size="19" class="mt-n1" v-bind="attrs"
                                            v-on="on">mdi-replay</v-icon>
                                        </template>
                                        <span>Frecuencia: {{ cli.frecuencia }}</span>
                                      </v-tooltip>
                                      <span class="font-weight-medium text-uppercase">
                                        {{ cli.frecuencia | truncFrecuencia }}
                                      </span>
                                    </span>
                                    <span v-if="cli.visita" class="blue-grey--text mr-2">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            :color="cli.pedido === true ? 'blue-grey lighten-1' : 'blue-grey lighten-4'"
                                            size="19" class="mt-n1" v-bind="attrs" v-on="on"
                                            v-text="cli.pedido === true ? 'mdi-text-box-check-outline' : 'mdi-text-box-remove-outline'" />
                                        </template>
                                        <span v-text="cli.pedido === true ? 'Pedidos Registrados' : 'Sin Pedidos'" />
                                      </v-tooltip>
                                    </span>
                                    <span v-if="cli.cobro !== null" class="blue-grey--text mr-2"
                                      style="position: relative;">
                                      <div v-if="!cli.cobro" class="point-sale-remove"></div>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            :color="cli.cobro === true ? 'blue-grey lighten-1' : 'blue-grey lighten-4'"
                                            size="19" class="mt-n1" v-bind="attrs" v-on="on"
                                            v-text="'mdi-point-of-sale'" />
                                        </template>
                                        <span v-text="cli.cobro === true ? 'Cobro Registrado' : 'Sin Cobros'" />
                                      </v-tooltip>
                                    </span>
                                    <span class="blue-grey--text mr-2">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon
                                            :color="cli.lat !== null && cli.lng !== null ? 'blue-grey lighten-1' : 'blue-grey lighten-4'"
                                            size="19" class="mt-n1" v-bind="attrs" v-on="on"
                                            v-text="cli.lat !== null && cli.lng !== null ? 'mdi-map-marker-radius-outline' : 'mdi-map-marker-off-outline'" />
                                        </template>
                                        <span
                                          v-text="cli.lat !== null && cli.lng !== null ? 'Cliente Geolocalizado' : 'Cliente sin Geolocalización'" />
                                      </v-tooltip>
                                    </span>
                                    <template>
                                      <v-chip class="mr-1" label x-small color="icono" dark
                                        style="padding: 0 10px !important" v-if="cli.lunes === '1'">
                                        LU
                                      </v-chip>
                                      <v-chip class="mr-1" label x-small color="purple" dark
                                        style="padding: 0 10px !important" v-if="cli.martes === '1'">
                                        MA
                                      </v-chip>
                                      <v-chip class="mr-1" label x-small color="info" dark
                                        style="padding: 0 10px !important" v-if="cli.miercoles === '1'">
                                        MI
                                      </v-chip>
                                      <v-chip class="mr-1" label x-small color="orange" dark
                                        style="padding: 0 10px !important" v-if="cli.jueves === '1'">
                                        JU
                                      </v-chip>
                                      <v-chip class="mr-1" label x-small color="secondary" dark
                                        style="padding: 0 10px !important" v-if="cli.viernes === '1'">
                                        VI
                                      </v-chip>
                                    </template>
                                  </div>
                                  <!-- <v-chip
                                small
                                color="grey lighten-4"
                                class="py-3"
                                label
                              >
                              </v-chip> -->
                                </div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="i < visita.clientes.length - 1" />
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-divider />
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- ######DETALLES DE CLIENTE #####-->
    <v-navigation-drawer v-model="showDetailsClient" absolute color="grey lighten-5" width="30vw" class="elevation-1">
      <v-app-bar dark color="blue-grey">
        <v-btn icon @click="showDetailsClient = !showDetailsClient">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ cliDetails.cli_des }} ({{ cliDetails.co_cli }})</v-toolbar-title>
      </v-app-bar>
      <v-divider class="mb-4"></v-divider>
      <v-row>

      </v-row>
    </v-navigation-drawer>

    <size-market v-model="sizeIcon" />
    <modal-cliente-export v-model="showClientExport" :clients="clientsExport" />

    <modal-cliente-map v-model="showClientMap" :client="clientDetails" />

    <v-btn fab dark style="position: fixed;  bottom:1rem" color="primary" @click="showInfoData = true">
      <v-icon>mdi-account-cog</v-icon>
    </v-btn>

    <v-dialog v-model="showDetails" max-width="70%" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="blue-grey" dense flat>
            <v-toolbar-title class="white--text subtitle-text">
              <v-icon color="white" size="20" class="mt-n1 mr-1">mdi-text-box</v-icon>
              <span class="font-weight-regular">
                Detalles de Visita
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showDetails = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <detalles-visita :visita="detailsData" class="ma-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import moment from 'moment'
import { getMapsUbucation, getVisitasPlanificadas } from '@/services/vendedores/'

const generarColor = () => {
  const h = Math.floor(Math.random() * 360),
    s = Math.floor(Math.random() * 100) + '%',
    l = Math.floor(Math.random() * 50) + '%';
  return `hsl(${h},${s},${l})`;
}

const colorWeekend = {
  0: '#2196F3',
  1: '#C62828',
  2: '#00695C',
  3: '#FDD835',
  4: '#6A1B9A',
  5: '#E91E63',
  6: '#388E3C'
}

export default {
  name: 'ItemRuta',
  components: {
    DetallesVisita: () => import(
      /* webpackChunkName: "detalles-visita" */
      '../ProgramaVisitas/DetallesVisita.vue'
    ),

    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    ConsultarRangoFechaMapa: () => import(
      /* webpackChunkName: "consultar-rango-fecha-mapa" */
      '../ConsultarRangoFechaMapa.vue'
    ),
    ModalClienteExport: () => import(
      /* webpackChunkName: "modal-cliente-export" */
      '@/views/Clientes/Components/ModalClienteExport.vue'
    ),
    ModalClienteMap: () => import(
      /* webpackChunkName: "modal-client-map" */
      '@/views/Clientes/Components/ModalClienteMap.vue'
    ),
    SizeMarket: () => import(
      /* webpackChunkName: "size-market" */
      '@/views/Clientes/Components/SizeMarket.vue'
    ),
  },
  props:{
    seller:{
      type: Object,
      default: () => '',
    }
  },
  data: () => ({
    detailsData: {},
    showDetails: false,
    co_ven: '',
    search_vend: '',
    showInfoData: true,
    loading: false,
    itemsVend: [],
    center_cli: {
      lat: null,
      lng: null,
    },
    action: false,
    clientsExport: [],
    lines: [],
    showClientExport: false,
    sizeIcon: 'mini',
    zoom_map: 7,
    showClientMap: false,
    venDetails: {},
    cliDetails: {},
    clientDetails: {},
    customVisitas: [],
    showListVend: true,
    showDetailsVend: false,
    showDetailsClient: false,
    showCustomVisit: false,
    visita: {},
    fechas: {
      ini: moment().startOf('week').day(1).format("YYYY-MM-DD"),
      fin: moment().day(6).format("YYYY-MM-DD"),
      selected: 'semana'
    },
    toggle_all: true
  }),
  created() {
    this.getVendedoresMap();
    this.mini = true;
    this.asigSeller();
  },
  watch: {
    toggle_all(val) {
      this.customVisitas.forEach(element => element.active = val);
    },
  },
  methods: {
    async viewDetails(vis){
      console.log(vis.co_cli);
      const prog_visita = await this.$api.get(`vendedores/${vis.co_ven}/programavisita2?fecha_inicio=${moment().format("YYYY-MM-DD")}&fecha_final=${moment().format("YYYY-MM-DD")}`);

      const result_visits = prog_visita.data.data[moment().format("DD-MM-YYYY")].clientes;

      const visit = result_visits.find(visit => String(visit.co_cli).trim() == vis.co_cli);

      const visit2 = visit.visita;

      if(visit)
      this.detailsData = {
      ...visit2,
        lat_cliente: visit2?.lat,
        lng_cliente: visit2?.lng,
        pedido: visit.pedido
      };

        this.showDetails = true
    },

    async asigSeller(){
      const data = await getMapsUbucation({ fecha: moment().format("YYYY-MM-DD") });

      const venSelec = data.find((vendedor) => {
        return vendedor.co_ven == this.seller.user.co_ven;
      });

      this.getVendMap(venSelec);
    },
    async getVendedoresMap() {
      this.loading = true;
      this.itemsVend = [];
      try {
        const data = await getMapsUbucation({ fecha: moment().format("YYYY-MM-DD") });
        // this.itemsVend = data.length > 0
        //   ? data.map(item => {
        //     return {
        //       ...item,
        //       color: generarColor(),
        //       loading: false
        //     }
        //   })
        //   : [];
          this.action = true;
          //const this.itemsVend = this.itemsVend.filter(item => item.co_ven == this.seller.user.co_ven);
          //console.log(prue);
      } catch (error) {
        console.error({ error })
      } finally {
        this.loading = false;
      }
    },
    async getVendMap(vend) {
      //console.log(vend);
      vend.loading = true;
      this.venDetails = {}
      try {
        const data = await getMapsUbucation({
          fecha: moment().format("YYYY-MM-DD"),
          co_ven: vend.co_ven
        });
        this.venDetails = {
          ...vend,
          visitas: data.length > 0
            ? data.map(item => {
              return {
                ...item,
                color: generarColor(),
              }
            })
            : []
            //[this.venDetails = {'cli_des':"Sin Visitas",'co_ven':"11",'hora_inicio':"00:00:00",'id':'12','lat':"0.0",'lng':"0.0"}]
            //[this.venDetails = {...vend}]
        }
        this.showListVend = !this.showListVend
        this.showDetailsVend = true

      } catch (error) {
        console.error({ error })
      } finally {
        vend.loading = false;
      }
    },
    async getCustomVisits(vend = null) {

      if (vend !== null) {
        vend.loading = true
        this.venDetails = { ...vend }
      }
      try {
        const data = await getVisitasPlanificadas({
          fecha_inicio: this.fechas.ini,
          fecha_final: this.fechas.fin,
          co_ven: vend?.co_ven ?? this.venDetails?.co_ven
        });
        const fechas = Object.keys(data);
        if (fechas.length > 0) {
          this.customVisitas = []
          let index = 0;
          for (const key in fechas) {
            const diaSemana = moment(fechas[key].split('-').reverse().join('-')).format("d");
            if (diaSemana !== '0') {
              this.customVisitas.push({
                fecha: fechas[key].split('-').reverse().join('-'),
                dia_semana: diaSemana,
                color: colorWeekend[index] ?? '#607D8B',
                clientes: data[fechas[key]].clientes ?? [],
                active: true
              })
            }
            index++;
          }
        }
        this.showCustomVisit = true
      } catch (error) {
        console.error({ error })
      } finally {
        this.action = false;
        this.loading = false;
        if (vend !== null) vend.loading = false;
      }
    },

    centerCliente(cli) {
      this.action = true;
      if (cli.lat != null && cli.lng != null) {
        this.center_cli = {
          lat: parseFloat(cli.lat.trim()),
          lng: parseFloat(cli.lng.trim()),
        }
        this.zoom_map = 15;
      }
    },
    exportarClientes(vertices) {
      this.clientsExport = [];
      this.clientsExport = vertices;
      this.showClientExport = true;
    },
    backList() {
      this.showListVend = false
      this.showDetailsVend = true
      this.showCustomVisit = false
    },
    getData({ ini, fin, selected }) {
      this.fechas.ini = ini;
      this.fechas.fin = fin;
      this.fechas.selected = selected;
      this.loading = true
      this.getCustomVisits()
    },
    visita_duracion(init, end) {
      if (init !== null && end !== null) {
        const today = moment().format('YYYY-MM-DD')
        const inicio = moment(`${today} ${init}`);
        const fin = moment(`${today} ${end}`);
        const duracion = fin.diff(inicio)

        const hours = Math.floor(moment.duration(duracion).asHours()).toString()

        return `${hours.length < 2 ? hours.padStart(2, '0') : hours}:${moment.utc(duracion).format("mm:ss")}`
      }
      return null
    },
    detailsClientes(info) {
      this.showClientMap = true
      this.clientDetails = { ...info }
    },
  },
  computed: {
    status: get('app/statusClient'),
    mini: sync('app/mini'),
    height_mapa() {
      return {
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '86vh',
      }
    },
    height_content_cliente() {
      return {
        'max-height': '100vh',
      }
    },
    itemsCustomVisits() {
      return this.customVisitas?.length > 0
        ? this.customVisitas.filter(item => item.active)
        : []
    },
    data_cliente() {
      if (this.itemsVend.length === 0) return []

      return this.itemsVend.filter(cli => {
        if (cli === null || cli.lat === null) return false
        return (cli.status_cuenta_vencida && this.active_status.rojo) || (cli.status_cuenta_dolar && this.active_status.amarillo) || (!cli.status_cuenta_vencida && !cli.status_cuenta_dolar && this.active_status.verde)
      }).filter(vend => {
        const indexVend = this.itemsVend.findIndex(v => v.co_ven === vend.co_ven);
        return indexVend >= 0 ? this.itemsVend[indexVend].active : false;
      })
    },
    markersCustom() {
      const points = []
      if (this.itemsCustomVisits.length > 0) {
        for (let i = 0; i < this.itemsCustomVisits.length; i++) {
          const visit = this.itemsCustomVisits[i];
          console.log(visit);
          visit.clientes.forEach(item => {
            points.push({
              name: item.cli_des,
              co_cli: item.co_cli,
              co_ven: item.co_ven,
              rif: item.rif,
              ven: item.ven_des,
              position: {
                lat: parseFloat(item?.lat?.trim()) ?? null,
                lng: parseFloat(item?.lng?.trim()) ?? null,
              },
              icon: {
                markerColor: visit.color,
                statusColor: '#FFFFFF',
              },
              number: i + 1,
              animation: null,
            })
          })
        }
      }
      return points;
    },
    markers() {
      const data = this.showListVend ? [...this.itemsVend] : this.showDetailsVend ? [...this.venDetails.visitas] : []

      if (this.showCustomVisit) return this.markersCustom

      return data.length > 0
        ? data.map((item, index) => ({
          name: item.cli_des,
          co_cli: item.co_cli,
          co_ven: item.co_ven,
          rif: item.rif,
          ven: item.ven_des,
          position: {
            lat: parseFloat(item.lat.trim()),
            lng: parseFloat(item.lng.trim()),
          },
          icon: {
            markerColor: item.color,
            statusColor: '#ffffff',
          },
          number: index + 1,
          animation: null,

        }))
        : []
    },
    stop(e) {
      // e?.preventDefault();
      console.log(e)
    }
  },
  filters: {
    diaSemana(date) {
      return moment(date).format("dddd, DD MMM")
    },
    truncFrecuencia(val) {
      return val.substr(0, 1);
    }
  }
}
</script>
<style lang="sass">
.pointer-cursor
  cursor: pointer

.open-info-map
  position: fixed
  z-index: 6
  top: 0
  transform: translateX(0%)
  margin-left: -12px

.close-info-map
  position: fixed
  z-index: 6
  top: 0
  transform: translateX(-100%)
  visibility: hidden

.infoClients
  transition: all 5s ease-out
  -webkit-transition: all 5s ease-out

.custom-chip .v-chip--select
  margin-top: 4px !important
  margin-bottom: 4px !important

.btn-detail
  width: 70%
  height: 30px !important
  background-color: #4DB6AC !important
  color: #FFFFFF !important
  font-size: 0.50rem !important
</style>
